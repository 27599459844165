<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="订单编号：">
        <el-input size="small" v-model="order_no" @input="getKeywords($event, 1)"></el-input>
      </el-form-item>
      <el-form-item label="服务名称：">
        <el-input size="small" v-model="goods_name" @input="getKeywords($event, 2)"></el-input>
      </el-form-item>
      <el-form-item label="下单时间：">
        <el-date-picker
          v-model="searchForm.create_time"
          @change="val => (val ? '' : (searchForm.create_time = []))"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button plain size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-tabs type="card" v-model="searchForm.state_type" @tab-click="tabClick">
      <el-tab-pane name="all">
        <span slot="label">全部订单（{{ order_num.state_all }}）</span>
      </el-tab-pane>
      <el-tab-pane name="state_new">
        <span slot="label">待付款（{{ order_num.state_new }}）</span>
      </el-tab-pane>
      <el-tab-pane name="state_pay">
        <span slot="label">已支付（{{ order_num.state_pay }}）</span>
      </el-tab-pane>
      <el-tab-pane name="state_unevaluate">
        <span slot="label">已完成（{{ order_num.state_unevaluate }}）</span>
      </el-tab-pane>
      <el-tab-pane name="state_cancel">
        <span slot="label">已取消（{{ order_num.state_cancel }}）</span>
      </el-tab-pane>
    </el-tabs>
    <div class="order-list">
      <table class="order-table">
        <thead>
          <tr>
            <th>服务信息</th>
            <th>单价/数量</th>
            <th>服务人</th>
            <th>总价</th>
            <th>预约人</th>
            <th>下单时间</th>
            <th>支付方式</th>
            <th>订单状态</th>
            <th>操作</th>
          </tr>
          <tr style="height: 10px"></tr>
        </thead>
        <tbody v-if="!list.length">
          <tr style="background: #fff">
            <td colspan="11">
              <No />
            </td>
          </tr>
        </tbody>
        <tbody v-for="(item, index) in list" :key="index">
          <tr>
            <td colspan="11">
              <div class="order-head">
                <div class="left">
                  <span class="sn">订单编号：{{ item.pay_sn }}</span>
                </div>
                <div class="right">
                  <el-button type="text" @click="$router.push({ path: '/order/serviceOrderInfo', query: { id: item.id } })">订单详情</el-button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="service-info">
                <div class="item" v-for="goodsInfo in item.extend_order_goods" :key="goodsInfo.id">
                  <el-image :src="goodsInfo.goods_img"></el-image>
                  <div class="goods-title">
                    <p>{{ goodsInfo.goods_title }}</p>
                    <p v-if="goodsInfo.sku_info">规格：{{ goodsInfo.sku_info.title }}</p>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="goods-price">
                <p v-for="goodsPrice in item.extend_order_goods" :key="goodsPrice.id">
                  <span>￥{{ goodsPrice.goods_price }}</span>
                  <span>x{{ goodsPrice.goods_num }}</span>
                </p>
              </div>
            </td>
            <td>{{ item.extend_appointment_info ? item.extend_appointment_info.service_name : '--' }}</td>
            <td>￥{{ item.amount }}</td>
            <td>
              <block v-if="item.extend_appointment_info">
                <p>{{ item.extend_appointment_info.nick_name }}</p>
                <p>{{ item.extend_appointment_info.name }}</p>
                <p>{{ item.extend_appointment_info.mobile }}</p>
              </block>
              <p v-else>--</p>
            </td>
            <td>
              {{ getDateformat(item.create_time) }}
            </td>
            <td>{{ item.order_type ? '到店支付' : '在线支付' }}</td>
            <td>
              <span
                :style="{
                  color: item.state == 0 || item.state == 40 ? '#409EFF' : item.state == 10 ? '#f56c6c' : '#e6a23c',
                }"
              >
                <span>
                  {{ item.state == 0 ? '已关闭' : item.state == 10 ? '待付款' : item.state == 20 ? '已支付' : item.state == 40 ? '已完成' : '' }}
                </span>
              </span>
            </td>
            <td>
              <el-button v-if="item.state == 10" type="text" @click="comfirmOrder(item.id)">确认支付</el-button>
              <el-button v-if="item.state == 10 || item.state == 20" type="text" @click="cancelOrder(item)">取消订单</el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <el-dialog title="取消订单" :visible.sync="showCancelOrder" width="35%">
      <el-form v-if="currentSelectOrderInfo.id" label-width="85px">
        <el-form-item label="取消原因：">
          <el-select v-model="cancel_reason_id" filterable placeholder="请选择取消原因">
            <el-option v-for="item in cancelList" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="取消说明：">
          <el-input v-model="state_remark" type="textarea" :rows="8" placeholder="请输入取消说明"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCancelOrder = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmCancelOrder">确 定</el-button>
      </span>
    </el-dialog>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>
<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import { mapState } from 'vuex';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      order_no: '',
      receiver_phone: '',
      receiver_name: '',
      goods_name: '',
      order_num: {},
      currentSelectOrderInfo: {},
      state_remark: '',
      cancel_reason_id: '',
      showCancelOrder: !1,
      searchForm: {
        page: 1,
        rows: 10,
        keywords_type: '',
        keywords: '',
        create_time: [],
        state_type: 'all',
      },
    };
  },
  computed: {
    ...mapState({
      cancelList: state => state.order.cancelList,
    }),
  },
  watch: {
    showCancelOrder(val) {
      if (val && !this.cancelList.length) this.$store.dispatch('order/getCancelList', { type: 4 });
    },
  },
  created() {
    this.getList();
  },
  methods: {
    tabClick() {
      this.searchForm.page = 1;
      this.searchForm.rows = 10;
      this.getList(1);
    },
    search() {
      this.searchForm.page = 1;

      this.getList();
    },
    cancelSearch() {
      this.order_no = '';
      this.goods_name = '';
      this.searchForm = {
        page: 1,
        rows: 10,
        keywords_type: '',
        keywords: '',
        create_time: [],
        state_type: 'all',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    getKeywords(val, type) {
      let searchForm = this.searchForm;
      switch (type) {
        case 1:
          this.goods_name = '';
          searchForm.keywords_type = 'order_no';
          break;
        case 2:
          this.order_no = '';
          searchForm.keywords_type = 'goods_name';
          break;
      }
      searchForm.keywords = val;
    },
    getList(is_tab) {
      let searchForm = Object.assign(this.searchForm);
      let data = {
        page: searchForm.page,
        rows: searchForm.rows,
        state_type: searchForm.state_type,
      };
      if (searchForm.keywords) {
        data.keywords = searchForm.keywords;
        data.keywords_type = searchForm.keywords_type;
      }
      if (searchForm.create_time.length) {
        for (let i in searchForm.create_time) {
          searchForm.create_time[i] = searchForm.create_time[i] / 1000;
        }
        data.create_time = searchForm.create_time;
      }
      this.$axios.post(this.$api.beauty.orderList, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
      if (!is_tab) {
        this.$axios.post(this.$api.beauty.orderNum, data).then(res => {
          if (res.code == 0) {
            this.order_num = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    //取消订单
    cancelOrder(row) {
      this.currentSelectOrderInfo = row;
      this.state_remark = '';
      this.showCancelOrder = !0;
    },
    comfirmCancelOrder() {
      if (!this.cancel_reason_id)
        return this.$message({
          message: '请选择取消原因',
          type: 'warning',
        });
      if (!this.state_remark)
        return this.$message({
          message: '请填写取消说明',
          type: 'warning',
        });
      this.$axios
        .post(this.$api.beauty.cancelOrder, {
          id: this.currentSelectOrderInfo.id,
          state_remark: this.state_remark,
          cancel_reason_id: this.cancel_reason_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.showCancelOrder = !1;
            this.$message({
              message: '订单已取消',
              type: 'success',
            });
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    comfirmOrder(id) {
      this.$confirm(`是否确认支付？`, '提示', {
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post(this.$api.beauty.confirmPayment, { id: id }).then(res => {
          if (res.code == 0) {
            this.$message({
              message: '确认成功',
              type: 'success',
            });
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  background: #fff;
  .order-table {
    width: 100%;
    font-size: 14px;
    tbody {
      border: 1px solid #ebeef5;
    }
    thead tr th {
      padding: 15px;
      text-align: center;
    }
    thead tr {
      background: #f8f9fa;
      th:first-child {
        width: 280px;
      }
      th:last-child {
        width: 150px;
      }
    }
    tbody tr:first-child {
      border-bottom: 1px solid #ebeef5;

      background: #f8f9fa;
      td {
        padding: 0 20px;
      }
    }
    tbody tr:last-child {
      td {
        text-align: center;
      }
      td:first-child {
        width: 280px;
      }
      td:last-child {
        width: 150px;
      }
    }
    .service-info {
      width: 300px;
      flex: none;
      padding-left: 20px;

      .item {
        height: 70px;
        display: flex;
        align-items: center;
        .el-image {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          border-radius: 5px;
        }
        .service-title {
          text-align: left;
          flex: 1;
          p:nth-child(1) {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
    .order-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        .sn {
          margin-right: 70px;
        }
        div {
          display: flex;
          align-items: center;
        }
        .el-button {
          cursor: auto;
        }
      }
    }
  }
  .goods-price p {
    flex: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70px;
    justify-content: center;
    span:nth-child(1) {
      margin-bottom: 5px;
    }
  }
}
</style>